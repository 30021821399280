<template>
    <section class="ExpertisePanel">
        <header class="ExpertisePanel-content">
            <div :class="`ExpertisePanel-text${alternateClass}`" class="ExpertisePanel-text">
                <h1 class="title ExpertisePanel-title">{{this.headerWord}}</h1>
                <div class="ExpertisePanel-description" v-html="this.text"></div><!-- data-simplebar -->
                <footer :class="`ExpertisePanel-footer${alternateClass}`" class="ExpertisePanel-footer">
                    <h3>{{this.label}}</h3>
                </footer>
            </div>
        </header>
        <div :class="`ExpertisePanel-background${alternateClass}`" class="ExpertisePanel-background">
            <div class="ExpertisePanel-focus">
                <figure
                        :class="`ExpertisePanel-illustration ExpertisePanel-illustration${index + 1}`"
                        :id="`ExpertisePanel-${iconKey}_illustration${index + 1}`"
                        :key="file"
                        v-for="(file, index) in illustrations">
                    <img :src="`img/expertise/${file}`" alt="" />
                </figure>
                <svg :id="`ExpertisePanel-${iconKey}_bg`" class="ExpertisePanel-bg" v-if="this.bg">
                    <use v-bind:xlink:href="`#symbol-Pattern${this.bg}`"></use>
                </svg>
                <div :id="`ExpertisePanel-${iconKey}_pattern`" :style="`background-image: url('img/expertise/pattern${this.pattern}.png')`" class="ExpertisePanel-pattern" v-if="this.pattern"></div>
                <div :id="`ExpertisePanel-${iconKey}_circle_big`" class="ExpertisePanel-circle ExpertisePanel-circle_big"></div>
                <div :id="`ExpertisePanel-${iconKey}_circle_small`" class="ExpertisePanel-circle ExpertisePanel-circle_small"></div>
            </div>
        </div>
    </section>
</template>

<script>
    import 'simplebar/dist/simplebar.min.css';

    export default {
        name: 'expertiseContent',
        props: {
            iconKey: String,
            label: String,
            headerWord: String,
            illustrations: Array,
            bg: String,
            pattern: String,
            text: String,
            index: Number
        },
        data() {
            return {
                alternateClass: this.index % 2 ? '_Right' : '_Left'
            };
        },
        computed: {
            patternFile() {
                if (this.firstName === undefined || this.lastName === undefined) {
                    return false;
                }
                return this.firstName.concat(this.lastName).length > 15;
            }
        }
    };
</script>
